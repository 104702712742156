:root {
  --plone-toolbar-width: 0 !important;
}

#portal-sidebar {
  width: 300px;
}

#portal-sidebar .plone-icon {
  vertical-align: -0.125rem;
}

#portal-sidebar .toggle-icon {
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

#portal-sidebar .sidebar-heading[aria-expanded="true"] .toggle-icon {
  transform: rotate(90deg);
}

#portal-sidebar .scrollarea {
  overflow-y: auto;
}

#portal-sidebar a {
  color: var(--bs-dark);
}

[data-bs-theme=dark] #portal-sidebar a {
  color: var(--bs-light);
}
